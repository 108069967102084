import * as React from "react";
import { Helmet } from "react-helmet";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../components/layout";
import Cta from "../components/cta";
import * as Styles from "../styles/effect.module.css";

const Warranty = () => {
  return (
    <Layout pageTitle="Home Page">
      {/* MetaTag */}
      <Helmet
        htmlAttributes={{
          lang: "en",
        }}
      >
        <title>
          Warranty Statement - KATSANA® Integrated Fleet Management & Industrial
          Operation Solutions
        </title>
        <meta
          name="description"
          content="We are transforming fleet & enterprise operations for the digital age."
        />
        <meta
          name="robots"
          content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
        />
        <link
          rel="canonical"
          href="https://www.katsana.com/warranty-statement/"
        />

        {/* Open Graph */}
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="https://www.katsana.com/warranty-statement/"
        />
        <meta
          property="og:title"
          content="Warranty Statement - KATSANA® Integrated Fleet Management & Industrial Operation Solutions"
        />
        <meta
          property="og:description"
          content="We are transforming fleet & enterprise operations for the digital age."
        />
        <meta
          property="og:image"
          content="https://assets.katsana.com/og-images/twt_ogimage_large.jpg"
        />
        <meta
          property="og:image:alt"
          content="KATSANA Fleet Management & Industrial IoT Sensors Expert"
        />
        <meta property="og:image:width" content="1280" />
        <meta property="og:image:height" content="640" />

        {/* Twitter Card */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@katsanagps" />
        <meta name="twitter:creator" content="@katsanagps" />

        {/* image must be 2:1 max 5mb */}
        <meta
          name="twitter:image"
          content="https://assets.katsana.com/og-images/twt_ogimage_large.jpg"
        />
      </Helmet>
      {/* Hero Area */}
      <div className="relative p-8 pt-12 pb-24 overflow-hidden xl:px-0  md:pt-32 md:pb-48">
        <StaticImage
          alt="katsana"
          src="../../static/images/photos/aboutus-hero.jpg"
          objectFit="cover"
          className="!absolute inset-0"
        />

        <div className="relative z-10 max-w-6xl mx-auto ">
          <h3 className="pt-24 md:pt-12 text-lg md:text-2xl text-bluegray">
            Important stuff that you need to know
          </h3>
          <h1 className="pt-2 text-3xl font-bold tracking-wide text-white md:leading-tight md:mt-0 md:text-6xl">
            Warranty Statement
          </h1>
        </div>
      </div>

      <div className=" bg-gradient-to-b from-[hsla(204,26%,93%,1)] to-white relative pb-36">
        <div className="absolute top-0 left-0 w-full">
          <div
            className={
              Styles.tilt +
              " after:bg-[hsla(204,26%,93%,1)] z-10 m-[-1px] after:h-[4vh] md:after:h-[10vh] z-10 "
            }
          ></div>
        </div>
        <div className="max-w-4xl p-8 xl:p-16 mx-auto bg-gradient-to-b from-[hsla(204,26%,93%,0)] to-[hsla(204,26%,99%,0)] via-white text-gray-800  relative">
          <h3 className="text-2xl font-medium">Warranty Statement</h3>
          <p className="mt-6 leading-7">
            We are committed to delivering you with the highest quality GPS
            Tracking system, both hardware and software in Malaysia and the
            world.
          </p>

          <h4 className="mt-6 text-xl font-medium">
            Limited Hardware Warranty
          </h4>
          <p className="mt-6 leading-7">
            This KATSANA Holdings Sdn Bhd (hereinafter KATSANA) product warranty
            extends to the original purchaser with hardware installed on the
            original vehicle it is intended to. Original purchaser must fill the
            warranty information card and send it to KATSANA upon purchase.
            <br />
            <br />
            KATSANA warrants all electronic hardware products against defects in
            materials and workmanship for a period of two years from date of
            sale. If KATSANA receives notice of such defects during the warranty
            period, KATSANA will, at its option, either repair or replace
            products, when proved to be defective.
            <br />
            <br />
            Should KATSANA be unable to repair or replace the product within a
            reasonable amount of time, the customer’s alternate remedy shall be
            a refund of the purchase price upon return of the product to
            KATSANA. This warranty gives the customer specific legal rights.
            Other rights, which vary from state to state or province to
            province, may be available.
          </p>
          <h4 className="mt-6 text-xl font-medium">Exclusions</h4>
          <p className="mt-6 leading-7">
            The above warranty shall not apply to defects resulting from:
          </p>
          <ul className="mt-4 ml-6 leading-7 list-disc">
            <li>
              A. Conditions, malfunctions or damage resulting from normal wear
              and tear, improper installation, misuse, abuse, negligence,
              accident or alteration. unauthorized modification, and operation
              outside of the environmental specifications for the product.
            </li>
            <li>
              B. Conditions, malfunctions or damage not resulting from defects
              in material.
            </li>
            <li>
              C. Accessories, connected materials and products, or related
              products not manufactured by KATSANA.
            </li>
          </ul>
          <p className="mt-6 leading-7">
            Our limited warranties are void if a product is returned with
            removed, damaged or tampered labels or any alterations (including
            removal of any component or external cover).
          </p>

          <h4 className="mt-6 text-xl font-medium">Filing a Claim</h4>
          <p className="mt-6 leading-7">
            KATSANA will not provide any warranty coverage unless claims are
            made in compliance with all terms of the controlling warranty
            statement included with your product and you follow proper return
            procedure.
            <br />
            <br />
            To request warranty service, you will need to provide:
          </p>
          <ul className="mt-4 ml-6 leading-7 list-disc">
            <li>
              1. The sales receipt or other evidence of the date and place of
              purchase.
            </li>
            <li>2. A description of the problem.</li>
            <li>
              3. Delivery of the product or the defective part, carefully packed
              and insured to KATSANA Sdn Bhd.
            </li>
          </ul>

          <h4 className="mt-6 text-xl font-medium">WARRANTY LIMITATIONS</h4>
          <p className="mt-6 leading-7">
            KATSANA MAKES NO OTHER WARRANTY, EITHER EX- PRESSED OR IMPLIED, WITH
            RESPECT TO THIS PRODUCT. KATSANA SPECIFICALLY DISCLAIMS THE IMPLIED
            WARRANTIES OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE.
          </p>

          <h4 className="mt-6 text-xl font-medium">EXCLUSIVE REMEDIES</h4>
          <p className="mt-6 leading-7">
            THE REMEDIES PROVIDED HEREIN ARE THE CUSTOMER’S SOLE AND EXCLUSIVE
            REMEDIES. IN NO EVENT SHALL KATSANA BE LIABLE FOR ANY DIRECT,
            INDIRECT, SPECIAL, INCIDENTAL, OR CONSEQUENTIAL DAMAGES, WHETHER
            BASED ON CONTRACT, TORT, OR ANY OTHER LEGAL THEORY.
          </p>
          <p className="mt-6 leading-7">
            © Copyright 2014-2017 KATSANA HOLDINGS SDN BHD.
          </p>
        </div>
      </div>
      <Cta />
    </Layout>
  );
};

export default Warranty;
